import { useEffect, useRef, useState } from 'react';
import './HomePage.scss';
import axios from 'axios';
import robotImage from '../../assets/img/robot.png';
import { useSelector } from 'react-redux';
import translations from '../../translations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDotCircle } from '@fortawesome/free-regular-svg-icons';
import greeceImage from '../../assets/img/greece.jpeg';
import europeImage from '../../assets/img/europe.png';
import usaImage from '../../assets/img/usa.jpeg';
import australiaImage from '../../assets/img/australia.jpeg';
import africaImage from '../../assets/img/africa.webp';
import asiaImage from '../../assets/img/asia.jpeg';

function searchGreekWords(input, words) {
  // Normalize the input string to lowercase and remove accents
  const normalizedInput = input.normalize("NFD").toLowerCase().normalize("NFC");

  // Helper function to find matches
  function findMatches(regex, text) {
    let match;
    const numbers = [];
    let found = false;
    while ((match = regex.exec(text)) !== null) {
      found = true;
      if (match[1]) {
        numbers.push(parseInt(match[1], 10));
      }
    }
    return { found, numbers };
  }

  let keywordsFound = false;
  const numbersBefore = [];

  // Iterate through the provided words and find matches
  words.forEach(word => {
    const normalizedWord = word.normalize("NFD").toLowerCase().normalize("NFC");
    const regex = new RegExp(`(\\d+)?\\s*${normalizedWord}`, 'gi');
    const { found, numbers } = findMatches(regex, normalizedInput);
    if (found) {
      keywordsFound = true;
    }
    numbersBefore.push(...numbers);
  });

  return { keywordsFound, numbersBefore };
}

const HomePage = (props) => {
  const [articles, setArticles] = useState([]);
  const [loadingArticles, setLoadingArticles] = useState(true);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [isLoadingMessage, setIsLoadingMessage] = useState(false);

  const optionsElRef = useRef(null);

  const languageCode = useSelector((state) => state.app.language.code);
  const t = translations[languageCode];

  useEffect(() => {
    axios.get(`https://eltv.news/api/articles?limit=6&key=1298`).then((response) => {
      setArticles(response.data.articles);
      setLoadingArticles(false);
    });
  }, []);

  useEffect(() => {
    if (messages.length === 0) {
      const m = messages.slice();

      m.push({
        you: false,
        they: true,
        text: 'Πως μπορώ να σας βοηθήσω;'
      });

      setMessages(m);
    } else {
      setTimeout(() => {
        const messagesContainer = document.querySelector('.ai-chat-container .messages-container');
        messagesContainer.scrollTop = messagesContainer.scrollHeight;
      });

      const lastMessage = messages[messages.length - 1];
      
      if (lastMessage.you) {
        const groups = [
          {
            name: 'restaurants',
            objectDesc: 'Ελληνικά εστιατόρια',
            keywords: ['Ελληνικό', 'εστιατόριο'],
            results: [
              '1. <a href="https://www.tripadvisor.com.gr/Restaurant_Review-g60763-d7814915-Reviews-Loi_Estiatorio-New_York_City_New_York.html" target="_blank">Loi Estiatorio</a> - 132 West 58th Street',
              '2. <a href="https://www.tripadvisor.com.gr/Restaurant_Review-g60763-d4101797-Reviews-Nerai-New_York_City_New_York.html" target="_blank">Nerai</a> - 55 E 54th St Between Madison Avenue',
              '3. <a href="https://www.tripadvisor.com.gr/Restaurant_Review-g60763-d425831-Reviews-Pylos-New_York_City_New_York.html" target="_blank">Pylos</a> - 128 E 7th St',
              '4. <a href="https://www.tripadvisor.com.gr/Restaurant_Review-g29837-d426027-Reviews-Taverna_Kyclades-Astoria_Queens_New_York.html" target="_blank">Taverna Kyclades</a> - 3307 Ditmars Blvd 36th Street',
              '5. <a href="https://www.tripadvisor.com.gr/Restaurant_Review-g60763-d424460-Reviews-Molyvos-New_York_City_New_York.html" target="_blank">Molyvos</a> - 402 W 43rd St 7th Avenue',
            ],
          },
          {
            name: 'restaurants',
            objectDesc: 'Ελληνικά εστιατόρια',
            keywords: ['Ελληνικο', 'εστιατοριο'],
            results: [
              '1. <a href="https://www.tripadvisor.com.gr/Restaurant_Review-g60763-d7814915-Reviews-Loi_Estiatorio-New_York_City_New_York.html" target="_blank">Loi Estiatorio</a> - 132 West 58th Street',
              '2. <a href="https://www.tripadvisor.com.gr/Restaurant_Review-g60763-d4101797-Reviews-Nerai-New_York_City_New_York.html" target="_blank">Nerai</a> - 55 E 54th St Between Madison Avenue',
              '3. <a href="https://www.tripadvisor.com.gr/Restaurant_Review-g60763-d425831-Reviews-Pylos-New_York_City_New_York.html" target="_blank">Pylos</a> - 128 E 7th St',
              '4. <a href="https://www.tripadvisor.com.gr/Restaurant_Review-g29837-d426027-Reviews-Taverna_Kyclades-Astoria_Queens_New_York.html" target="_blank">Taverna Kyclades</a> - 3307 Ditmars Blvd 36th Street',
              '5. <a href="https://www.tripadvisor.com.gr/Restaurant_Review-g60763-d424460-Reviews-Molyvos-New_York_City_New_York.html" target="_blank">Molyvos</a> - 402 W 43rd St 7th Avenue',
            ],
          },
          {
            name: 'restaurants',
            objectDesc: 'Ελληνικά εστιατόρια',
            keywords: ['Ελληνικά', 'εστιατόρια'],
            results: [
              '1. <a href="https://www.tripadvisor.com.gr/Restaurant_Review-g60763-d7814915-Reviews-Loi_Estiatorio-New_York_City_New_York.html" target="_blank">Loi Estiatorio</a> - 132 West 58th Street',
              '2. <a href="https://www.tripadvisor.com.gr/Restaurant_Review-g60763-d4101797-Reviews-Nerai-New_York_City_New_York.html" target="_blank">Nerai</a> - 55 E 54th St Between Madison Avenue',
              '3. <a href="https://www.tripadvisor.com.gr/Restaurant_Review-g60763-d425831-Reviews-Pylos-New_York_City_New_York.html" target="_blank">Pylos</a> - 128 E 7th St',
              '4. <a href="https://www.tripadvisor.com.gr/Restaurant_Review-g29837-d426027-Reviews-Taverna_Kyclades-Astoria_Queens_New_York.html" target="_blank">Taverna Kyclades</a> - 3307 Ditmars Blvd 36th Street',
              '5. <a href="https://www.tripadvisor.com.gr/Restaurant_Review-g60763-d424460-Reviews-Molyvos-New_York_City_New_York.html" target="_blank">Molyvos</a> - 402 W 43rd St 7th Avenue',
            ],
          },
          {
            name: 'restaurants',
            objectDesc: 'Ελληνικά εστιατόρια',
            keywords: ['Ελληνικα', 'εστιατορια'],
            results: [
              '1. <a href="https://www.tripadvisor.com.gr/Restaurant_Review-g60763-d7814915-Reviews-Loi_Estiatorio-New_York_City_New_York.html" target="_blank">Loi Estiatorio</a> - 132 West 58th Street',
              '2. <a href="https://www.tripadvisor.com.gr/Restaurant_Review-g60763-d4101797-Reviews-Nerai-New_York_City_New_York.html" target="_blank">Nerai</a> - 55 E 54th St Between Madison Avenue',
              '3. <a href="https://www.tripadvisor.com.gr/Restaurant_Review-g60763-d425831-Reviews-Pylos-New_York_City_New_York.html" target="_blank">Pylos</a> - 128 E 7th St',
              '4. <a href="https://www.tripadvisor.com.gr/Restaurant_Review-g29837-d426027-Reviews-Taverna_Kyclades-Astoria_Queens_New_York.html" target="_blank">Taverna Kyclades</a> - 3307 Ditmars Blvd 36th Street',
              '5. <a href="https://www.tripadvisor.com.gr/Restaurant_Review-g60763-d424460-Reviews-Molyvos-New_York_City_New_York.html" target="_blank">Molyvos</a> - 402 W 43rd St 7th Avenue',
            ],
          },
        ];
        
        for (let i = 0; i < groups.length; i++) {
          const result = searchGreekWords(lastMessage.text, groups[i].keywords);

          if (result.keywordsFound) {
            let number = result.numbersBefore[0] || groups[i].results.length;

            const m = messages.slice();

            if (number > groups[i].results.length) {
              m.push({ you: false, they: true, text: `Βρήκα έως ${groups[i].results.length} ${groups[i].objectDesc}.`});
              number = groups[i].results.length;
            } else {
              m.push({ you: false, they: true, text: `Ωρίστε ${number === 1 ? 'ένα' : number} ${groups[i].objectDesc}:`});
            }

            setIsLoadingMessage(true);

            (async function() {
              await new Promise((resolve) => setTimeout(resolve, 1200));
              setIsLoadingMessage(false);
              setMessages(m);

              for (let j = 0; j < number; j++) {
                const _m = m.slice();
                
                m.push({ you: false, they: true, text: `${groups[i].results[j]}`});
                
                setMessages(_m);

                await new Promise((resolve) => setTimeout(resolve, 500));
              }

              setMessages(m);
            })();

            break;
          }
        }
      }
    }
  }, [messages]);

  useEffect(() => {
    if (optionsElRef.current) {
      for (let i = 0; i < optionsElRef.current.children.length; i++) {
        setTimeout(((i) => {
          return () => {
            optionsElRef.current.children[i].classList.add('rendered');
          };
        })(i), (i + 1) * 130);
      }
    }
  }, [optionsElRef.current]);

  const onMessageInputKeyDown = (e) => {
    if (e.keyCode === 13 && message.trim().length > 0) {
      const m = messages.slice();

      m.push({
        you: true,
        they: false,
        text: message,
      });

      setMessage('');
      setMessages(m);
    }
  };

  const continents = [
    'greece',
    'europe',
    'usa',
    'oceania',
    'africa',
    'asia',
  ];

  const continentsImages = {
    greece: greeceImage,
    europe: europeImage,
    usa: usaImage,
    oceania: australiaImage,
    africa: africaImage,
    asia: asiaImage,
  };

  return (
    <div className="HomePage">
      {/* <div className="topbar">
        
      </div> */}

      <div className="ai-chat-container">
        <div className="header">
          <h1>Βοηθός AI</h1>
          <img src={robotImage} height="100" />  
        </div>

        <div className="chat-window">
          <div className="header">
            <img src={robotImage} />
            <span>Βοηθός AI</span>
          </div>

          <div className="messages-container">
            {
              messages.map((message, i) => {
                return (
                  <div
                    key={i}
                    className={`message${message.you ? ' you' : ''}${message.they ? ' they' : ''}${i === 0 || (i > 0 && message.you !== messages[i - 1].you) ? ' margin-top' : ''}`}
                  >
                    {message.they && (i === 0 || (i > 0 && messages[i - 1].you)) && <img src={robotImage} className="avatar" />}
                    {message.they && (i > 0 && messages[i - 1].they) && <div className="avatar-margin"></div>}
                    <span
                      className={`${message.you && i > 0 && messages[i - 1].you ? 'top-connected' : ''} ${(message.you && i + 1 <= (messages.length - 1)) && messages[i + 1].you ? 'bottom-connected' : ''} ${message.they && i > 0 && messages[i - 1].they ? 'top-connected' : ''} ${(message.they && i + 1 <= (messages.length - 1)) && messages[i + 1].they ? 'bottom-connected' : ''}`}
                      dangerouslySetInnerHTML={{ __html: message.text }}
                    ></span>
                  </div>
                )
              })
            }
            {
              isLoadingMessage && <div className="loading-message">
                <img src={robotImage} className="avatar" />

                <span>
                  <FontAwesomeIcon icon={faDotCircle} size="xs"></FontAwesomeIcon>
                  <FontAwesomeIcon icon={faDotCircle} size="xs"></FontAwesomeIcon>
                  <FontAwesomeIcon icon={faDotCircle} size="xs"></FontAwesomeIcon>
                </span>
              </div>
            }
          </div>

          <div className="footer">
            <input type="text" placeholder="Αα" value={message} onChange={(e) => setMessage(e.target.value)} onKeyDown={onMessageInputKeyDown} />
          </div>
        </div>
      </div>

      <div className="continents">
        <h1>Ανακαλύψτε οργανισμούς - φορείς - επιχειρήσεις</h1>

        <div className="items">
          {
            continents.map((continent, i) => {
              return (
                <div
                  className="item"
                  key={i}
                >
                  <div className="image">
                    <img src={continentsImages[continent]} />
                  </div>

                  <div className="title">
                    {`${languageCode === 'el' ? 'Σε ' : ''}
                    ${t[continent]}`}
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>

      <div className="news">
        <h1>Τελευταία Νέα</h1>

        <div className="articles">
          {
            !loadingArticles && articles.length > 0 && articles.map((article, i) => {
              return (
                <div
                  className="article"
                  key={i}
                >
                  <div className="image" style={{ backgroundImage: `url('https://eltv.news/data/articles/${article.image}')` }}></div>
                  <div className="title">{article.title}</div>
                </div>
              );
            })
          }

          {
            (loadingArticles || articles.length === 0) && Array.from(Array(6)).map((n, i) => {
              return (
                <div
                  className="article-skeleton-item"
                  key={i}
                >
                  <div className="image"></div>
                  <div className="title"></div>
                </div>
              );
            })
          }
        </div>
      </div>

      {/* <div className="sections">
        <div
          className="options"
          ref={optionsElRef}
        >
          <div className="option">
            <div className="fi fi-us fis"></div>
            <span>Αμερική (H.Π.Α.)</span>
          </div>

          <div className="option">
            <div className="fi fi-ca fis"></div>
            <span>Καναδάς</span>
          </div>

          <div className="option">
            <div className="fi fi-eu fis"></div>
            <span>Ευρώπη</span>
          </div>

          <div className="option">
            <div className="fi fi-au fis"></div>
            <span>Αυστραλία</span>
          </div>

          <div className="option">
            <div className="fi fi-za fis"></div>
            <span>Νότια Αφρική</span>
          </div>

          <div className="option">
            <div className="fi fi-cn fis"></div>
            <span>Κίνα</span>
          </div>
        </div>
      </div>

      <div className="announcements">
        <h1>Ανακοινώσεις</h1>

        <div className="items">
          {
            Array.from(Array(10)).map((n, i) => {
              return (
                <div
                  className="skeleton-item"
                  key={i}
                >
                  
                </div>
              );
            })
          }
        </div>
      </div> */}
    </div>
  );
};

export default HomePage;