import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: 'app',
  initialState: {
    language: {
      code: localStorage.getItem('language') || 'el',
      isMenuOpen: false,
    },
    weatherData: null,
  },
  reducers: {
    setLanguage: (state, action) => {
      state.language.code = action.payload.languageCode;

      localStorage.setItem('language', action.payload.languageCode);
    },
    setLanguageMenu: (state, action) => {
      state.language.isMenuOpen = action.payload.open;
    },
    setWeatherData: (state, action) => {
      state.weatherData = action.payload.data;
    },
  },
});

export default appSlice;
