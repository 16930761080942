import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import Header from './components/header/Header';
import './overrides.scss';
import HomePage from './components/home-page/HomePage';
import LanguageMenu from './components/language-menu/LanguageMenu';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <Header></Header>
          <LanguageMenu></LanguageMenu>

          <main>
            <Routes>
              <Route exact path="/" element={<HomePage />}></Route>
            </Routes>
          </main>
        </BrowserRouter>
    </div>
  );
}

export default App;
