const translations = {
  en: {
    currentLanguage: 'English',
    see_more: 'See More...',
    news: "News",
    weather: "Weather",
    radio: "Radio",
    btnMenu: 'Menu',
    noAvailableArticles: 'There are no available articles for this section',
    noAvailableArticlesWithSuchSearchTerm: 'No available articles with such search term',
    btnClose: 'Close',
    categories: 'Categories',
    latestNews: 'Latest news',
    church: 'Church',
    education: 'Education',
    events: 'Events',
    business: 'Business',
    culture: 'Culture',
    organizations: 'Organizations',
    politics: 'Politics',
    opinion: 'Opinion',
    traveling: 'Traveling',
    language: 'Language',
    search: 'Search',
    usa: 'USA · Canada · L. America',
    live: 'LIVE',
    greece: 'Greece',
    africa: 'Africa',
    asia: 'Asia',
    europe: 'Europe',
    oceania: 'Australia · Oceania',
    follow_us: 'Follow us',
    subscribe_to_newsletter: 'Subscribe to the Newsletter',
    button_subscribe: 'Subscribe',
    date: 'Date',
    category1: 'Back then',
    category2: 'Sealed and signed',
    category3: 'The wonders of the world',
    category4: 'Traveling with the lens of elTV',
    homepage_banner1: 'Greece in the world',
    footer: {
      contact: 'Contact',
      advertisment: 'Advertisement',
      terms_of_use: 'Terms of Use',
      protection_policy: 'Protection Policy',
      identity: 'Identity',
    },
    menu: {
      homepage: 'Home',
      live: 'Live',
      news: 'News',
      tvshows: 'TV Shows',
      entertainment: 'Entertainment',
      tvprogram: 'TV Program',
      trending: 'Trending',
      group: 'Group',
      advertise: 'Advertise',
      contact: 'Contact',
      help: 'Help',
    },
  },
  el: {
    currentLanguage: 'Ελληνικά',
    see_more: 'Δείτε περισσότερα...',
    news: "Επικαιρότητα",
    weather: "Καιρός",
    radio: "Ραδιόφωνο",
    btnMenu: 'Μενού',
    noAvailableArticles: 'Δεν υπάρχουν διαθέσιμα άρθρα για αυτή την ενότητα',
    noAvailableArticlesWithSuchSearchTerm: 'Δεν βρέθηκαν διαθέσιμα άρθρα με αυτόν τον όρο αναζήτησης',
    btnClose: 'Κλείσιμο',
    categories: 'Κατηγορίες',
    latestNews: 'Τελευταία νέα',
    church: 'Εκκλησία',
    education: 'Εκπαίδευση',
    events: 'Εκδηλώσεις',
    business: 'Επιχειρήσεις',
    culture: 'Πολιτισμός',
    organizations: 'Οργανισμοί - Φορείς',
    politics: 'Πολιτική',
    opinion: 'Άποψη',
    traveling: 'Ταξιδεύοντας',
    language: 'Γλώσσα',
    search: 'Αναζήτηση',
    usa: 'Αμερική · Καναδάς · Λ. Αμερική',
    live: 'LIVE',
    greece: 'Ελλάδα',
    africa: 'Αφρική',
    asia: 'Ασία',
    europe: 'Ευρώπη',
    oceania: 'Αυστραλία · Ωκεανία',
    follow_us: 'Ακολουθήστε μας',
    subscribe_to_newsletter: 'Εγγραφείτε στο Newsletter',
    button_subscribe: 'Εγγραφή',
    date: 'Ημερομηνία',
    category1: 'Τον καιρό εκείνο...',
    category2: 'Με σφραγίδα και υπογραφή',
    category3: 'Του κόσμου τα παράξενα',
    category4: 'Ταξιδεύοντας με τον φακό του ελTV',
    homepage_banner1: 'Η Ελλάδα στον κόσμο',
    footer: {
      contact: 'Επικοινωνία',
      advertisment: 'Διαφήμιση',
      terms_of_use: 'Όροι Χρήσης',
      protection_policy: 'Πολιτική Προστασίας',
      identity: 'Ταυτότητα',
    },
    menu: {
      homepage: 'Αρχική',
      live: 'Live',
      news: 'Ενημέρωση',
      tvshows: 'Σειρές',
      entertainment: 'Ψυχαγωγία',
      tvprogram: 'Πρόγραμμα',
      trending: 'Trending',
      group: 'Όμιλος',
      advertise: 'Διαφημιστείτε',
      contact: 'Επικοινωνία',
      help: 'Βοήθεια',
    },
  },
  es: {
    currentLanguage: 'Español',
    see_more: 'Ver más...',
    news: "Noticias",
    weather: "Tiempo",
    radio: "Radio",
    btnMenu: 'Menú',
    noAvailableArticles: 'No hay artículos disponibles para esta sección',
    noAvailableArticlesWithSuchSearchTerm: 'No se encontraron artículos disponibles con ese término de búsqueda',
    btnClose: 'Cerrar',
    categories: 'Categorías',
    latestNews: 'Últimas noticias',
    church: 'Iglesia',
    education: 'Educación',
    events: 'Eventos',
    business: 'Negocio',
    culture: 'Cultura',
    organizations: 'Organizaciones',
    politics: 'Política',
    opinion: 'Opinión',
    traveling: 'Viajando',
    language: 'Idioma',
    search: 'Buscar',
    usa: 'EE. UU. · Canadá · América Latina',
    live: 'EN VIVO',
    greece: 'Grecia',
    africa: 'África',
    asia: 'Asia',
    europe: 'Europa',
    oceania: 'Australia · Oceanía',
    follow_us: 'Síguenos',
    subscribe_to_newsletter: 'Suscríbete al Newsletter',
    button_subscribe: 'Suscribirse',
    date: 'Fecha',
    category1: 'En aquel entonces',
    category2: 'Sellado y firmado',
    category3: 'Las maravillas del mundo',
    category4: 'Viajando con la lente de elTV',
    homepage_banner1: 'Grecia en el mundo',
    footer: {
      contact: 'Contacto',
      advertisment: 'Publicidad',
      terms_of_use: 'Condiciones de uso',
      protection_policy: 'Política de Protección',
      identity: 'Identidad',
    },
    menu: {
      homepage: 'Inicio',
      live: 'En Vivo',
      news: 'Noticias',
      tvshows: 'Programas de TV',
      entertainment: 'Entretenimiento',
      tvprogram: 'Programa de TV',
      trending: 'Tendencias',
      group: 'Grupo',
      advertise: 'Publicidad',
      contact: 'Contacto',
      help: 'Ayuda',
    },
  },
  fr: {
    currentLanguage: 'Français',
    see_more: 'Voir plus...',
    news: "Nouvelles",
    weather: "Météo",
    radio: "Radio",
    btnMenu: 'Carte',
    noAvailableArticles: 'Il n\'y a pas d\'articles disponibles pour cette section',
    noAvailableArticlesWithSuchSearchTerm: 'Aucun article disponible ne correspond à ce terme de recherche',
    btnClose: 'Fermer',
    categories: 'Catégories',
    latestNews: 'Dernières nouvelles',
    church: 'Église',
    education: 'Éducation',
    events: 'Événements',
    business: 'Affaires',
    culture: 'Culture',
    organizations: 'Organisations',
    politics: 'Politique',
    opinion: 'Avis',
    traveling: 'Voyageant',
    language: 'Langue',
    search: 'Recherche',
    usa: 'États-Unis · Canada · Amérique latine',
    live: 'EN DIRECT',
    greece: 'Grèce',
    africa: 'Afrique',
    asia: 'Asie',
    europe: 'Europe',
    oceania: 'Australie · Océanie',
    follow_us: 'Suivez-nous',
    subscribe_to_newsletter: 'Abonnez-vous à la newsletter',
    button_subscribe: 'S\'abonner',
    date: 'Date',
    category1: 'À cette époque-là',
    category2: 'Signé et scellé',
    category3: 'Les merveilles du monde',
    category4: 'Voyager avec l\'objectif d\'elTV',
    homepage_banner1: 'La Grèce dans le monde',
    footer: {
      contact: 'Contact',
      advertisment: 'Publicité',
      terms_of_use: 'Conditions d\'utilisation',
      protection_policy: 'Politique de protection',
      identity: 'Identité',
    },
    menu: {
      homepage: 'Accueil',
      live: 'En Direct',
      news: 'Actualités',
      tvshows: 'Émissions de télévision',
      entertainment: 'Divertissement',
      tvprogram: 'Programme TV',
      trending: 'Tendance',
      group: 'Groupe',
      advertise: 'Publicité',
      contact: 'Contact',
      help: 'Aide',
    },
  },
  it: {
    currentLanguage: 'Italiano',
    see_more: 'Vedi altro...',
    news: "Notizie",
    weather: "Tempo",
    radio: "Radio",
    btnMenu: 'Menu',
    noAvailableArticles: 'Non ci sono articoli disponibili per questa sezione',
    noAvailableArticlesWithSuchSearchTerm: 'Nessun articolo disponibile con questo termine di ricerca',
    btnClose: 'Chiudere',
    categories: 'Categorie',
    latestNews: 'Ultime notizie',
    church: 'Chiesa',
    education: 'Istruzione',
    events: 'Eventi',
    business: 'Affari',
    culture: 'Cultura',
    organizations: 'Organizzazioni',
    politics: 'Politica',
    opinion: 'Opinione',
    traveling: 'Viaggiando',
    language: 'Lingua',
    search: 'Cerca',
    usa: 'USA · Canada · America Latina',
    live: 'IN DIRETTA',
    greece: 'Grecia',
    africa: 'Africa',
    asia: 'Asia',
    europe: 'Europa',
    oceania: 'Australia · Oceania',
    follow_us: 'Seguiamo',
    subscribe_to_newsletter: 'Iscriviti alla newsletter',
    button_subscribe: 'Sottoscrivere',
    date: 'Data',
    category1: 'A quel tempo',
    category2: 'Siglato e firmato',
    category3: 'Le meraviglie del mondo',
    category4: 'Viaggiando con l\'obiettivo di elTV',
    homepage_banner1: 'La Grecia nel mondo',
    footer: {
      contact: 'Contatto',
      advertisment: 'Pubblicità',
      terms_of_use: 'Condizioni d\'uso',
      protection_policy: 'Politica sulla privacy',
      identity: 'Identità',
    },
    menu: {
      homepage: 'Home',
      live: 'In Diretta',
      news: 'Notizie',
      tvshows: 'Programmi TV',
      entertainment: 'Intrattenimento',
      tvprogram: 'Programma TV',
      trending: 'Tendenze',
      group: 'Gruppo',
      advertise: 'Pubblicità',
      contact: 'Contatti',
      help: 'Aiuto',
    },
  },
  de: {
    currentLanguage: 'Deutsch',
    see_more: 'Mehr anzeigen...',
    news: "Nachrichten",
    weather: "Wetter",
    radio: "Radio",
    btnMenu: 'Speisekarte',
    noAvailableArticles: 'In diesem Abschnitt sind keine Artikel verfügbar',
    noAvailableArticlesWithSuchSearchTerm: 'Keine verfügbaren Artikel mit diesem Suchbegriff',
    btnClose: 'Schließen',
    categories: 'Kategorien',
    latestNews: 'Letzte Nachrichten',
    church: 'Kirche',
    education: 'Bildung',
    events: 'Veranstaltungen',
    business: 'Geschäft',
    culture: 'Kultur',
    organizations: 'Organisationen',
    politics: 'Politik',
    opinion: 'Meinung',
    traveling: 'Reisend',
    language: 'Sprache',
    search: 'Suche',
    usa: 'USA · Kanada · Lateinamerika',
    live: 'LIVE',
    greece: 'Griechenland',
    africa: 'Afrika',
    asia: 'Asien',
    europe: 'Europa',
    oceania: 'Australien · Ozeanien',
    follow_us: 'Folgen Sie uns',
    subscribe_to_newsletter: 'Abonnieren Sie den Newsletter',
    button_subscribe: 'Abonnieren',
    date: 'Datum',
    category1: 'Damals',
    category2: 'Versiegelt und unterschrieben',
    category3: 'Die Wunder der Welt',
    category4: 'Reisen mit der Linse von elTV',
    homepage_banner1: 'Griechenland in der Welt',
    footer: {
      contact: 'Kontakt',
      advertisment: 'Werbung',
      terms_of_use: 'Nutzungsbedingungen',
      protection_policy: 'Datenschutzrichtlinie',
      identity: 'Identität',
    },
    menu: {
      homepage: 'Startseite',
      live: 'Live',
      news: 'Nachrichten',
      tvshows: 'TV-Sendungen',
      entertainment: 'Unterhaltung',
      tvprogram: 'TV-Programm',
      trending: 'Trending',
      group: 'Gruppe',
      advertise: 'Werbung',
      contact: 'Kontakt',
      help: 'Hilfe',
    },
  },
  ru: {
    currentLanguage: 'Русский',
    see_more: 'Показать больше...',
    news: "Новости",
    weather: "Погода",
    radio: "Pадио",
    btnMenu: 'Меню',
    noAvailableArticles: 'В этом разделе нет доступных статей',
    noAvailableArticlesWithSuchSearchTerm: 'Нет доступных статей с таким поисковым запросом',
    btnClose: 'Закрыть',
    categories: 'Категории',
    latestNews: 'Последние новости',
    church: 'Церковь',
    education: 'Образование',
    events: 'События',
    business: 'Дело',
    culture: 'Культура',
    organizations: 'Организации',
    politics: 'Политика',
    opinion: 'Мнение',
    traveling: 'Путешествующий',
    language: 'Язык',
    search: 'Поиск',
    usa: 'США · Канада · Латинская Америка',
    live: 'В ЭФИРЕ',
    greece: 'Греция',
    africa: 'Африка',
    asia: 'Азия',
    europe: 'Европа',
    oceania: 'Австралия · Океания',
    follow_us: 'Подпишитесь на нас',
    subscribe_to_newsletter: 'Подпишитесь на рассылку',
    button_subscribe: 'Подписаться',
    date: 'Дата',
    category1: 'В то время',
    category2: 'Запечатано и подписано',
    category3: 'Чудеса света',
    category4: 'Путешествуя с объективом elTV',
    homepage_banner1: 'Греция в мире',
    footer: {
      contact: 'Контакт',
      advertisment: 'Реклама',
      terms_of_use: 'Условия использования',
      protection_policy: 'Политика защиты',
      identity: 'Личность',
    },
    menu: {
      homepage: 'Главная',
      live: 'Прямой эфир',
      news: 'Новости',
      tvshows: 'ТВ-передачи',
      entertainment: 'Развлечения',
      tvprogram: 'ТВ-программа',
      trending: 'Тенденции',
      group: 'Группа',
      advertise: 'Реклама',
      contact: 'Контакты',
      help: 'Помощь',
    },
  },
  sr: {
    currentLanguage: 'Srpski',
    see_more: 'Prikaži više...',
    news: "Vesti",
    weather: "Времето",
    radio: "Pадио",
    btnMenu: 'Мени',
    noAvailableArticles: 'У овом одељку нема доступних чланака',
    noAvailableArticlesWithSuchSearchTerm: 'Nema dostupnih članaka sa ovim pojmom pretrage',
    btnClose: 'Затвори',
    categories: 'Категорије',
    latestNews: 'Најновије вести',
    church: 'Црква',
    education: 'Образовање',
    events: 'Догађаји',
    business: 'Бизнес',
    culture: 'Култура',
    organizations: 'Организације',
    politics: 'Политика',
    opinion: 'Мишљење',
    traveling: 'Putovanje',
    language: 'Језик',
    search: 'Претрага',
    usa: 'САД · Канада · Латинска Америка',
    live: 'УЖИВО',
    greece: 'Грчка',
    africa: 'Африка',
    asia: 'Азија',
    europe: 'Европа',
    oceania: 'Аустралија · Океанија',
    follow_us: 'Pratite nas',
    subscribe_to_newsletter: 'Пријавите се на билтен',
    button_subscribe: 'Претплатити се',
    date: 'Датум',
    category1: 'U to vreme',
    category2: 'Otvoreno i potpisano',
    category3: 'Чуда света',
    category4: 'Путујући са објективом elTV',
    homepage_banner1: 'Грчка у свету',
    footer: {
      contact: 'Контакт',
      advertisment: 'Reklama',
      terms_of_use: 'Uslovi korišćenja',
      protection_policy: 'Политика заштите',
      identity: 'Идентитет',
    },
    menu: {
      homepage: 'Почетна',
      live: 'Уживо',
      news: 'Вести',
      tvshows: 'ТВ Емисије',
      entertainment: 'Забава',
      tvprogram: 'ТВ Програм',
      trending: 'Трендови',
      group: 'Група',
      advertise: 'Рекламирајте се',
      contact: 'Контакт',
      help: 'Помоћ',
    },
  },
  ro: {
    currentLanguage: 'Română',
    see_more: 'Vezi mai mult...',
    news: "Știri",
    weather: "Vreme",
    radio: "Radio",
    btnMenu: 'Meniu',
    noAvailableArticles: 'Nu există articole disponibile pentru această secțiune',
    noAvailableArticlesWithSuchSearchTerm: 'Niciun articol disponibil cu acest termen de căutare',
    btnClose: 'Închide',
    categories: 'Categorii',
    latestNews: 'Ultimele știri',
    church: 'Biserică',
    education: 'Educație',
    events: 'Evenimente',
    business: 'Afaceri',
    culture: 'Cultură',
    organizations: 'Organizații',
    politics: 'Politică',
    opinion: 'Opinie',
    traveling: 'Călătorind',
    language: 'Limbă',
    search: 'Căutare',
    usa: 'SUA · Canada · America Latină',
    live: 'ÎN DIRECT',
    greece: 'Grecia',
    africa: 'Africa',
    asia: 'Asia',
    europe: 'Europa',
    oceania: 'Australia · Oceania',
    follow_us: 'Urmărește-ne',
    subscribe_to_newsletter: 'Abonați-vă la Newsletter',
    button_subscribe: 'A se abona',
    date: 'Dată',
    category1: 'Pe atunci',
    category2: 'Sigilat și semnat',
    category3: 'Minunile lumii',
    category4: 'Călătorind cu lentila elTV',
    homepage_banner1: 'Grecia în lume',
    footer: {
      contact: 'Contact',
      advertisment: 'Publicitate',
      terms_of_use: 'Termeni de utilizare',
      protection_policy: 'Politica de protecție',
      identity: 'Identitate',
    },
    menu: {
      homepage: 'Acasă',
      live: 'În Direct',
      news: 'Știri',
      tvshows: 'Emisiuni TV',
      entertainment: 'Divertisment',
      tvprogram: 'Program TV',
      trending: 'Trenduri',
      group: 'Grup',
      advertise: 'Publicitate',
      contact: 'Contact',
      help: 'Ajutor',
    },
  },
  ar: {
    currentLanguage: 'العربية',
    see_more: 'عرض المزيد',
    news: "أخبار",
    weather: "الطقس",
    radio: "الراديو",
    btnMenu: 'قائمة طعام',
    noAvailableArticles: 'لا توجد مقالات متاحة لهذا القسم',
    noAvailableArticlesWithSuchSearchTerm: 'لا توجد مقالات متوفرة بهذا المصطلח البحث',
    btnClose: 'أغلق',
    categories: 'فئات',
    latestNews: 'آخر الأخبار',
    church: 'كنيسة',
    education: 'تعليم',
    events: 'أحداث',
    business: 'أعمال تجارية',
    culture: 'ثقافة',
    organizations: 'منظمات',
    politics: 'سياسة',
    opinion: 'رأي',
    traveling: 'مسافر',
    language: 'لغة',
    search: 'البحث',
    usa: 'الولايات المتحدة الأمريكية · كندا · أمريكا اللاتينية',
    live: 'مباشر',
    greece: 'اليونان',
    africa: 'أفريقيا',
    asia: 'آسيا',
    europe: 'أورُوبا',
    oceania: 'أستراليا · أوقيانوسيا',
    follow_us: 'تابعنا',
    subscribe_to_newsletter: 'اشترك في النشرة البريدية',
    button_subscribe: 'اشترك',
    date: 'تاريخ',
    category1: 'في ذلك الوقت',
    category2: 'مختوم وموقع عليه',
    category3: 'عجائب الدنيا',
    category4: 'السفر مع عدسة elTV',
    homepage_banner1: 'اليونان في العالم',
    footer: {
      contact: 'جهة اتصال',
      advertisment: 'إعلان',
      terms_of_use: 'شروط الاستخدام',
      protection_policy: 'سياسة الحماية',
      identity: 'هوية',
    },
    menu: {
      homepage: 'الرئيسية',
      live: 'مباشر',
      news: 'أخبار',
      tvshows: 'برامج تلفزيونية',
      entertainment: 'ترفيه',
      tvprogram: 'جدول البرامج',
      trending: 'الاتجاهات',
      group: 'مجموعة',
      advertise: 'إعلان',
      contact: 'اتصال',
      help: 'مساعدة',
    },
  },
  zh: {
    currentLanguage: '中文',
    see_more: '查看更多',
    news: "新闻",
    weather: "天气",
    radio: "收音机",
    btnMenu: '菜单',
    noAvailableArticles: '此部分没有可用的文章',
    noAvailableArticlesWithSuchSearchTerm: '找不到与此搜索词相关的可用文章',
    btnClose: '关闭',
    categories: '分类',
    latestNews: '最新消息',
    church: '教堂',
    education: '教育',
    events: '事件',
    business: '商业',
    culture: '文化',
    organizations: '组织',
    politics: '政治',
    opinion: '意见',
    traveling: '旅行',
    language: '语言',
    search: '搜索',
    usa: '美国 · 加拿大 · 拉丁美洲',
    live: '直播',
    greece: '希腊',
    africa: '非洲',
    asia: '亚洲',
    europe: '欧洲',
    oceania: '澳大利亚 · 大洋洲',
    follow_us: '关注我们',
    subscribe_to_newsletter: '订阅新闻稿',
    button_subscribe: '订阅',
    date: '日期',
    category1: '那时候',
    category2: '盖章并签名',
    category3: '世界奇观',
    category4: '通过 elTV 的镜头旅行',
    homepage_banner1: '希腊在世界',
    footer: {
      contact: '联系方式',
      advertisment: '广告',
      terms_of_use: '使用条款',
      protection_policy: '隐私政策',
      identity: '身份',
    },
    menu: {
      homepage: '首页',
      live: '直播',
      news: '新闻',
      tvshows: '电视节目',
      entertainment: '娱乐',
      tvprogram: '电视节目表',
      trending: '趋势',
      group: '群体',
      advertise: '广告',
      contact: '联系',
      help: '帮助',
    },
  },
};

export default translations;
