import './Header.scss';
import logo from '../../assets/img/logo.png';
import { faBars, faDotCircle, faRadio, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import translations from '../../translations';
import { Link, useNavigate } from 'react-router-dom';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import request from '../../request';
// import WeatherWidget from '../weather-widget/WeatherWidget';
import greeceFlagIcon from '../../assets/img/flag-icons/greece.png';
import ukFlagIcon from '../../assets/img/flag-icons/uk.png';
import romaniaFlagIcon from '../../assets/img/flag-icons/romania.png';
import serbiaFlagIcon from '../../assets/img/flag-icons/serbia.png';
import franceFlagIcon from '../../assets/img/flag-icons/france.png';
import russiaFlagIcon from '../../assets/img/flag-icons/russia.png';
import spainFlagIcon from '../../assets/img/flag-icons/spain.png';
import italyFlagIcon from '../../assets/img/flag-icons/italy.png';
import germanyFlagIcon from '../../assets/img/flag-icons/germany.png';
import chinaFlagIcon from '../../assets/img/flag-icons/china.png';
import uaeFlagIcon from '../../assets/img/flag-icons/uae.png';
import store from '../../store';
import appSlice from '../../store/app/appSlice';

const Header = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [quickSearchResults, setQuickSearchResults] = useState([]);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const menuElRef = useRef(null);
  const searchElRef = useRef(null);
  let searchTimeout = useRef(null);

  const languageCode = useSelector((state) => state.app.language.code);
  const t = translations[languageCode];

  const navigate = useNavigate();

  const onWindowClick = (e) => {
    if (!window.document.querySelector('.btn-show-menu').contains(e.target) && !menuElRef.current.contains(e.target)) {
      closeMenu();
    }

    if (e.target === menuElRef.current) {
      closeMenu();
    }

    if (!searchElRef.current.contains(e.target)) {
      setIsSearchOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', onWindowClick);

    return () => {
      window.removeEventListener('click', onWindowClick);
    };
  }, []);

  useEffect(() => {
    clearTimeout(searchTimeout.current);

    if (searchQuery.length > 2) {
      // searchTimeout.current = setTimeout(() => {
      //   request.get(`api/quick-search?q=${searchQuery}&language=${languageCode}`).then((response) => {
      //     if (response.data.query === searchQuery) {
      //       setQuickSearchResults(response.data.results);
      //     }
      //   });
      // }, 350);
    } else {
      setQuickSearchResults([]);
    }
  }, [searchQuery]);

  function openMenu() {
    setIsMenuOpen(true);
    
    window.document.body.classList.add('unscrollable');

    window.scrollTo(0, 0);
  }

  function closeMenu() {
    setIsMenuOpen(false);

    window.document.body.classList.remove('unscrollable');
  }

  function toggleMenu() {
    if (isMenuOpen) {
      closeMenu();
    } else {
      openMenu();
    }
  }

  function openLanguageMenu() {
    store.dispatch(appSlice.actions.setLanguageMenu({ open: true }));
  }

  const languageFlags = {
    'en': ukFlagIcon,
    'el': greeceFlagIcon,
    'es': spainFlagIcon,
    'fr': franceFlagIcon,
    'it': italyFlagIcon,
    'de': germanyFlagIcon,
    'ru': russiaFlagIcon,
    'sr': serbiaFlagIcon,
    'ro': romaniaFlagIcon,
    'ar': uaeFlagIcon,
    'zh': chinaFlagIcon,
  };

  return (
    <div className="Header">
      <div className="left">
        <div className="brand">
          <Link to="/" className="logo">
            <img src={logo} alt="ελTV logo" />
          </Link>
        </div>

        <div className="seperator"></div>

        <button
          className="btn-show-menu"
          onClick={toggleMenu}
        >
          {
            isMenuOpen
              ? <FontAwesomeIcon icon={faTimes} className="btn-show-menu__icon"></FontAwesomeIcon>
              : <FontAwesomeIcon icon={faBars} className="btn-show-menu__icon"></FontAwesomeIcon>
          }
          &nbsp;
        </button>

        <div className={`search-input${isSearchOpen ? ' open' : ''}`} ref={searchElRef}>
          <div className="icon" onClick={() => {
            setIsSearchOpen(!isSearchOpen);
            
            if (!isSearchOpen) {
              setTimeout(() => {
                searchElRef.current.querySelector('input').focus();
              });
            }
          }}>
            <FontAwesomeIcon icon={faSearch} size="lg" className="search__icon"></FontAwesomeIcon>
          </div>

          <button
            className={`btn-close${searchQuery.length === 0 ? ' d-none' : ''}`}
            onClick={() => {
              setSearchQuery('');

              setTimeout(() => {
                searchElRef.current.querySelector('input').focus();
              });
            }}
          >
            &times;</button>

          <input
            type="text"
            placeholder={t.search}
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                navigate(`/search/${searchQuery}`);
              }
            }}
          />

          <div className={`quick-results-container${quickSearchResults.length === 0 ? ' d-none' : ''}`}>
            {
              quickSearchResults.map((article, i) => {
                return (
                  <Link to={`/category/${article.category}/${article.subcategory}/${article.id}`}
                    key={article.id}
                  >
                    <img src={`${request.origin}data/articles/${article.image}`} />
                    <span>{article.title}</span>
                  </Link>
                );
              })
            }
          </div>
        </div>
      </div>

      <div className="right">
        <button
          className="btn-show-languages-menu"
          onClick={openLanguageMenu}
        >
          <img src={languageFlags[languageCode]} alt="Display language flag" />
          <span>{t.currentLanguage}</span>
        </button>
      </div>

      <div
        ref={menuElRef}
        className={`menu${isMenuOpen ? ' open' : ''}`}
      >
        <div className="content">
          {/* <WeatherWidget></WeatherWidget> */}

          <div className="options">
            <Link to="/">{t.menu.homepage}</Link>
            <Link to="/live">{t.menu.live}</Link>
            <a href="#">{t.menu.news}</a>
            <a href="#">{t.menu.tvshows}</a>
            <a href="#">{t.menu.entertainment}</a>
            <a href="#">{t.menu.tvprogram}</a>
            <a href="#">{t.menu.trending}</a>
          </div>

          <div className="info">
            <a href="#">{t.menu.group}</a>
            <a href="#">{t.menu.advertise}</a>
            <a href="#">{t.menu.contact}</a>
            <a href="#">{t.menu.help}</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
