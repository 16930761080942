import './LanguageMenu.scss';
import store from '../../store/index';
import appSlice from '../../store/app/appSlice';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useRef } from 'react';
import greeceFlagIcon from '../../assets/img/flag-icons/greece.png';
import ukFlagIcon from '../../assets/img/flag-icons/uk.png';
import romaniaFlagIcon from '../../assets/img/flag-icons/romania.png';
import serbiaFlagIcon from '../../assets/img/flag-icons/serbia.png';
import franceFlagIcon from '../../assets/img/flag-icons/france.png';
import russiaFlagIcon from '../../assets/img/flag-icons/russia.png';
import spainFlagIcon from '../../assets/img/flag-icons/spain.png';
import italyFlagIcon from '../../assets/img/flag-icons/italy.png';
import germanyFlagIcon from '../../assets/img/flag-icons/germany.png';
import chinaFlagIcon from '../../assets/img/flag-icons/china.png';
import uaeFlagIcon from '../../assets/img/flag-icons/uae.png';

const LanguageMenu = () => {

  const elementRef = useRef(null);

  const isOpen = useSelector((state) => state.app.language.isMenuOpen);

  useEffect(() => {
    elementRef.current.addEventListener('click', (e) => {
      if (e.target === elementRef.current) {
        store.dispatch(appSlice.actions.setLanguageMenu({ open: false }));
      }
    });
  }, []);

  function setLanguage(languageCode) {
    store.dispatch(appSlice.actions.setLanguage({ languageCode }));
    store.dispatch(appSlice.actions.setLanguageMenu({ open: false }));
  }

  return (
    <div
      className={`LanguageMenu ${isOpen ? 'open' : ''}`}
      ref={elementRef}
    >
      <div className="language-list">
        <button
          className="btn-close"
          onClick={() => {
            store.dispatch(appSlice.actions.setLanguageMenu({ open: false }));
          }}
        >
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </button>

        <div
          onClick={() => setLanguage('en')}
        >
          <img src={ukFlagIcon} alt="English flag" />
          <span>English</span>
        </div>

        <div
          onClick={() => setLanguage('el')}
        >
          <img src={greeceFlagIcon} alt="Ελληνικά flag" />
          <span>Ελληνικά</span>
        </div>

        <div
          onClick={() => setLanguage('es')}
        >
          <img src={spainFlagIcon} alt="Español flag" />
          <span>Español</span>
        </div>

        <div
          onClick={() => setLanguage('fr')}
        >
          <img src={franceFlagIcon} alt="Français flag" />
          <span>Français</span>
        </div>

        <div
          onClick={() => setLanguage('it')}
        >
          <img src={italyFlagIcon} alt="Italiano flag" />
          <span>Italiano</span>
        </div>

        <div
          onClick={() => setLanguage('de')}
        >
          <img src={germanyFlagIcon} alt="Deutsch flag" />
          <span>Deutsch</span>
        </div>

        <div
          onClick={() => setLanguage('ru')}
        >
          <img src={russiaFlagIcon} alt="Русский flag" />
          <span>Русский</span>
        </div>

        {/* <div
          onClick={() => setLanguage('sr')}
        >
          <img src={serbiaFlagIcon} alt="Srpski flag" />
          <span>Srpski</span>
        </div> */}

        <div
          onClick={() => setLanguage('ro')}
        >
          <img src={romaniaFlagIcon} alt="Română flag" />
          <span>Română</span>
        </div>

        <div
          onClick={() => setLanguage('ar')}
        >
          <img src={uaeFlagIcon} alt="العربية flag" />
          <span>العربية</span>
        </div>

        <div
          onClick={() => setLanguage('zh')}
        >
          <img src={chinaFlagIcon} alt="中文 flag" />
          <span>中文</span>
        </div>
      </div>
    </div>
  );
};

export default LanguageMenu;
