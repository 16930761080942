import axios from 'axios';

let baseURL = 'http://localhost/greekportal/';
let origin = 'http://localhost/greekportal/';

if (process.env.NODE_ENV === 'production') {
  baseURL = 'https://greekportal.org/';
  origin = 'https://greekportal.org/';
}

const axiosInstance = axios.create({
  baseURL,
  withCredentials: true,
});

const request = {
  origin,
  baseURL,
  axiosInstance,
  // throttling: 1500,

  get(url, params = {}) {
    let resolveFn, rejectFn;
    
    const getRequestPromise = new Promise((resolve, reject) => {
      resolveFn = resolve;
      rejectFn = reject;
    });

    setTimeout(() => {
      axiosInstance.get(url, params)
        .then((response) => {
          if (this.throttling > 0) {
            setTimeout(() => {
              resolveFn(response);
            }, this.throttling);
          } else {
            resolveFn(response);
          }
        })
        .catch((err) => {
          if (this.throttling > 0) {
            setTimeout(() => {
              rejectFn(err);
            }, this.throttling);
          } else {
            rejectFn(err);
          }
        });
    }, params.delay || 0);

    return getRequestPromise;
  },

  post(url, params) {
    let resolveFn, rejectFn;
    
    const postRequestPromise = new Promise((resolve, reject) => {
      resolveFn = resolve;
      rejectFn = reject;
    });

    axiosInstance.post(url, params)
      .then((response) => {
        if (this.throttling > 0) {
          setTimeout(() => {
            resolveFn(response);
          }, this.throttling);
        } else {
          resolveFn(response);
        }
      })
      .catch((err) => {
        if (this.throttling > 0) {
          setTimeout(() => {
            rejectFn(err);
          }, this.throttling);
        } else {
          rejectFn(err);
        }
      });

    return postRequestPromise;
  },
};

export default request;
